<template>
  <v-layout class="fill-height" >
    <v-app-bar
      app
      flat
      color="white"
      class="app-bar-full"
      clipped-left
      height="100"

    >

      <v-card
        class="flex-grow-1 d-flex pa-1"
        title
      >
        <div class="d-flex flex-grow-1 align-center">


          <div class="d-flex px-1 align-center">
            <v-img src="/images/IngramScholarsLogo2.jpg" :height="logoHeight"></v-img>

          </div>

          <v-spacer></v-spacer>
          <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">

          </div>

          <toolbar-user v-if="isAuthenticated" />
        </div>
      </v-card>
    </v-app-bar>

    <v-main class="bg-white">
      <v-container :fluid="!isContentBoxed" class="pt-3">
        <router-view class="py-2 fill-height"></router-view>
      </v-container>
    </v-main>


    <v-footer app>
      <div class="overline">
        Copyright © 2021 <a href="#" target="_blank">Ingram Scholars Program</a>, All rights Reserved
      </div>
      <v-spacer></v-spacer>
      <div class="overline">
      </div>
    </v-footer>
  </v-layout>
</template>

<script>
import ToolbarUser from '@/components/ingram/ToolbarUser'
import {mapGetters} from "vuex";

export default {
  name: "AccountLayout",
  components:{ToolbarUser},
  computed:{
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    }),
    logoHeight(){
      console.log(this.$vuetify)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '50px'
        case 'sm': return '50px'
        case 'md': return '50px'
        case 'lg': return '100px'
        default: return '100px'
      }
    }
  },
  data(){
    return{
      isContentBoxed: false
    }
  }
}
</script>

<style scoped>

</style>
